import {parse} from 'cookie';
import {Ampli, ampli as ampliGlobal, DefaultConfiguration as ampliGlobalConfig} from '../ampli/src/ampli/index.ts';

import {isProdEnv} from './index';

const AMPLI_ECDS = 'AMPLI_ECDS';
const AMPLI_BLDL = 'AMPLI_BLDL';
const BLDL_REGEX = /\/bldl/;

const ampliEssential = new Ampli();
const ampliBLDL = new Ampli();
let LOADED = false;
const ampliAPIEndpoint = `https://a.bloombergindustry.com/2/httpapi`;

/* istanbul ignore next */
let ampli = {
  appPageViewed: () => {},
  loginView: () => {},
  loginSubmitted: () => {},
  interactiveClick: () => {},
  rememberMe: () => {},
  academicRegistration: () => {},
  client: {},
  forgotUsernamePassword: () => {},
  errorViewed: () => {},
  formSubmitted: () => {},
  flush: () => {},
};

const ampliEnv = isProdEnv() ? 'production' : 'development';
// const ampliAPIEndpointBlaw = `https://a.bloomberglaw.com/2/httpapi`;
// const ampliAPIEndpointBtax = `https://a.bloombergtax.com/2/httpapi`;
// const ampliAPIEndpointBgov = `https://a.bgov.com/2/httpapi`;
// const ampliAPIEndpointCustomerTool = `https://a.bloombergindustry.com/2/httpapi`;

// __WIDGETAMPLI__ = {
//   ampliConfig: '',
//   ampliProxyUrl: '',
//   appName: '',
//   appVersion: '',
//   appBranch: '',
//   brand: '',
//   channel: '',
//   domain: '',
//   httpStatusCode: '200',
//   pageTitle: '',
//   pageType: '',
//   url: document.URL,
//   loginType: '',
//   softwareVersion: '',
// };

const ampliProps = {
  appName: 'indg-login',
  appVersion: ampliGlobalConfig?.plan?.version,
  brand: 'ECDS',
  channel: 'ECDS',
  domain: 'bloombergindustry.com',
  httpStatusCode: 200,
  pageTitle: 'Essential Login',
  pageType: 'Login Page',
  platform: 'ECDS',
  url: document.URL,
  isLoggedIn: false,
  loginType: 'Inline',
};

/** Amplitude Init */

const getAmpli = () => {
  if (!LOADED) {
    // console.log('init amplitude...');
    const cookies = parse(document.cookie);
    const uaString = window.navigator?.userAgent;
    const bid = cookies?.bid;

    ampliEssential.load({
      environment: `${ampliEnv}customertools`,
      client: {
        configuration: {
          serverUrl: ampliAPIEndpoint,
          includeUtm: true,
          includeReferrer: true,
        },
      },
    });
    ampliBLDL.load({
      environment: `${ampliEnv}bloomberglawsoftware`,
      client: {
        configuration: {
          serverUrl: ampliAPIEndpoint,
          includeUtm: true,
          includeReferrer: true,
        },
      },
    });
    ampliEssential.identify(null, {userAgent: uaString, bid});
    ampliBLDL.identify(null, {userAgent: uaString, bid});

    /** this is to make sure events fired before leaving the site */
    /** seems not working for post login redirect, but decided to keep it for now */
    window.addEventListener('pagehide', () => {
      ampliEssential.client.setTransport('beacon');
      ampliBLDL.client.setTransport('beacon');
      ampliEssential.flush();
      ampliBLDL.flush();
    });

    ampli = {
      ...ampli,
      formSubmitted: (properties, options, extra) =>
        selectAmpliInstance().formSubmitted({...ampliProps, ...properties}, options, extra),
      appPageViewed: (properties, options, extra) =>
        selectAmpliInstance().appPageViewed({...ampliProps, ...properties}, options, extra),
      loginView: (properties, options, extra) => selectAmpliInstance().loginView({...ampliProps, ...properties}, options, extra),
      loginSubmitted: (properties, options, extra) =>
        selectAmpliInstance().loginSubmitted({...ampliProps, ...properties}, options, extra),
      interactiveClick: (properties, options, extra) =>
        selectAmpliInstance().interactiveClick({...ampliProps, ...properties}, options, extra),
      rememberMe: (properties, options, extra) =>
        selectAmpliInstance().rememberMe(
          {...{itemClicked: 'RememberMe', itemLocation: 'Essential', itemType: 'checkbox'}, ...ampliProps, ...properties},
          options,
          extra
        ),
      academicRegistration: (properties, options, extra) =>
        selectAmpliInstance().academicRegistration({...ampliProps, ...properties}, options, extra),
      client: selectAmpliInstance().client,
      forgotUsernamePassword: (properties, options, extra) =>
        selectAmpliInstance().forgotUsernamePassword({...ampliProps, ...properties}, options, extra),
      errorViewed: (properties, options, extra) =>
        selectAmpliInstance().errorViewed({...ampliProps, ...properties}, options, extra),
      multiFactorAuthenticationSubmitted: (properties, options, extra) =>
        selectAmpliInstance().multiFactorAuthenticationSubmitted({...ampliProps, ...properties}, options, extra),
      multiFactorAuthenticationViewed: (properties, options, extra) =>
        selectAmpliInstance().multiFactorAuthenticationViewed({...ampliProps, ...properties}, options, extra),
      identify: (userId, properties = {}, options) =>
        selectAmpliInstance().identify(userId, {userAgent: uaString, bid, ...properties}, options),
      flush: () => selectAmpliInstance().flush(),
    };
    LOADED = true;
  }
  return ampli;
};

const selectAmpliInstance = () => (BLDL_REGEX.test(window.location.pathname) ? ampliBLDL : ampliEssential);

export default getAmpli;
